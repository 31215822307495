<template>
	<div class="container">
		<h1 class="display-6 fw-light">Arbeitsgruppen des Vereins</h1>
		<div class="maingroups row">
			<div class="col-lg-4 pe-xxl-8" v-for="group in maingroups" :key="group.id">
				<div class="card mb-4">
					<div class="card-header">
						<router-link :to="{ name: 'Arbeitsgruppe', params: { slug: group.slug } }">
							<h5 class="text-center mb-3" v-text="group.post.title"></h5>
						</router-link>
					</div>
					<div class="card-body text-center">
						<router-link :to="{ name: 'Arbeitsgruppe', params: { slug: group.slug } }">
							<i :class="getIcon(group.icon)"></i>
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<h1 class="display-6 fw-light">Weitere Gruppen</h1>
		<div class="othergroups row">
			<div class="col-lg-4 pe-xxl-8" v-for="group in othergroups" :key="group.id">
				<div class="card mb-4">
					<div class="card-header">
						<router-link :to="{ name: 'Arbeitsgruppe', params: { slug: group.slug } }">
							<h5 class="text-center mb-3" v-text="group.post.title"></h5>
						</router-link>
					</div>
					<div class="card-body text-center">
						<router-link :to="{ name: 'Arbeitsgruppe', params: { slug: group.slug } }">
							<i :class="getIcon(group.icon)"></i>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import _ from 'lodash';
export default {
	name: "GroupOverview",
	props: ["groups"],
	methods: {
		getIcon(icon)
		{
			return "text-green fad fa-4x " + icon;
		}
	},
	computed: {
		maingroups() {
			return _.orderBy(this.groups.filter(q => q.status == "show"), 'slug', 'asc');
		},
		othergroups() {
			return _.orderBy(this.groups.filter(q => q.status == "hidden"), 'slug', 'asc');
		}
	},
	mounted () {
		window.scrollTo(0, 0)
	}
};
</script>