<template>
	<div class="groups">
		<SectionHeader :image="null" title="Arbeitsgruppen" page="Alle" />
		<GroupOverview :groups="groups"/>
	</div>
</template>
<script>
import GroupOverview from '@/views/GroupOverview.vue'
import SectionHeader from '@/views/SectionHeader'
export default {
	name: "Groups",
	created() {
		this.$store.dispatch('loadGroups');
	},
	components: {
		GroupOverview,
		SectionHeader
	},
	computed: {
		groups() {
			return this.$store.state.groups;
		},
		error() {
			return this.$store.state.loadingError;
		},
		loading() {
			return this.$store.state.loadingStatus;
		}
	},
	mounted() {
	},
};
</script>